export const initialWarframes = [
  {
    name: "Ash",
    gender: "male",
    primeUmbra: "standard",
    auraPolarity: ["madurai"],
    progenitorElement: "radiation",
    hasLeverian: true,
    releaseYear: 2012,
    image: require("../assets/png/warframe_profiles/ash.png"),
  },
  {
    name: "Ash Prime",
    gender: "male",
    primeUmbra: "prime",
    auraPolarity: ["madurai"],
    progenitorElement: "radiation",
    hasLeverian: true,
    releaseYear: 2015,
    image: require("../assets/png/warframe_profiles/ash-prime.png"),
  },
  {
    name: "Atlas",
    gender: "male",
    primeUmbra: "standard",
    auraPolarity: ["madurai"],
    progenitorElement: "toxin",
    hasLeverian: true,
    releaseYear: 2015,
    image: require("../assets/png/warframe_profiles/atlas.png"),
  },
  {
    name: "Atlas Prime",
    gender: "male",
    primeUmbra: "prime",
    auraPolarity: ["madurai"],
    progenitorElement: "toxin",
    hasLeverian: true,
    releaseYear: 2019,
    image: require("../assets/png/warframe_profiles/atlas-prime.png"),
  },
  {
    name: "Banshee",
    gender: "female",
    primeUmbra: "standard",
    auraPolarity: ["madurai"],
    progenitorElement: "electricity",
    hasLeverian: false,
    releaseYear: 2013,
    image: require("../assets/png/warframe_profiles/banshee.png"),
  },
  {
    name: "Banshee Prime",
    gender: "female",
    primeUmbra: "prime",
    auraPolarity: ["madurai"],
    progenitorElement: "electricity",
    hasLeverian: false,
    releaseYear: 2017,
    image: require("../assets/png/warframe_profiles/banshee-prime.png"),
  },
  {
    name: "Baruuk",
    gender: "male",
    primeUmbra: "standard",
    auraPolarity: ["naramon"],
    progenitorElement: "impact",
    hasLeverian: false,
    releaseYear: 2018,
    image: require("../assets/png/warframe_profiles/baruuk.png"),
  },
  {
    name: "Baruuk Prime",
    gender: "male",
    primeUmbra: "prime",
    auraPolarity: ["naramon"],
    progenitorElement: "impact",
    hasLeverian: false,
    releaseYear: 2022,
    image: require("../assets/png/warframe_profiles/baruuk-prime.png"),
  },
  {
    name: "Caliban",
    gender: "male",
    primeUmbra: "standard",
    auraPolarity: ["naramon"],
    progenitorElement: "electricity",
    hasLeverian: false,
    releaseYear: 2021,
    image: require("../assets/png/warframe_profiles/caliban.png"),
  },
  {
    name: "Chroma",
    gender: "male",
    primeUmbra: "standard",
    auraPolarity: ["madurai"],
    progenitorElement: "heat",
    hasLeverian: false,
    releaseYear: 2015,
    image: require("../assets/png/warframe_profiles/chroma.png"),
  },
  {
    name: "Chroma Prime",
    gender: "male",
    primeUmbra: "prime",
    auraPolarity: ["madurai"],
    progenitorElement: "heat",
    hasLeverian: false,
    releaseYear: 2018,
    image: require("../assets/png/warframe_profiles/chroma-prime.png"),
  },
  {
    name: "Citrine",
    gender: "female",
    primeUmbra: "standard",
    auraPolarity: ["naramon"],
    progenitorElement: "magnetic",
    hasLeverian: false,
    releaseYear: 2023,
    image: require("../assets/png/warframe_profiles/citrine.png"),
  },
  {
    name: "Dagath",
    gender: "female",
    primeUmbra: "standard",
    auraPolarity: ["zenurik"],
    progenitorElement: "toxin",
    hasLeverian: false,
    releaseYear: 2023,
    image: require("../assets/png/warframe_profiles/dagath.png"),
  },
  {
    name: "Dante",
    gender: "male",
    primeUmbra: "standard",
    auraPolarity: ["universal"],
    progenitorElement: "impact",
    hasLeverian: true,
    releaseYear: 2024,
    image: require("../assets/png/warframe_profiles/dante.png"),
  },
  {
    name: "Ember",
    gender: "female",
    primeUmbra: "standard",
    auraPolarity: ["madurai"],
    progenitorElement: "heat",
    hasLeverian: false,
    releaseYear: 2012,
    image: require("../assets/png/warframe_profiles/ember.png"),
  },
  {
    name: "Ember Prime",
    gender: "female",
    primeUmbra: "prime",
    auraPolarity: ["madurai"],
    progenitorElement: "heat",
    hasLeverian: false,
    releaseYear: 2013,
    image: require("../assets/png/warframe_profiles/ember-prime.png"),
  },
  {
    name: "Equinox",
    gender: "female",
    primeUmbra: "standard",
    auraPolarity: ["naramon"],
    progenitorElement: "radiation",
    hasLeverian: false,
    releaseYear: 2015,
    image: require("../assets/png/warframe_profiles/equinox.png"),
  },
  {
    name: "Equinox Prime",
    gender: "female",
    primeUmbra: "prime",
    auraPolarity: ["naramon"],
    progenitorElement: "radiation",
    hasLeverian: false,
    releaseYear: 2019,
    image: require("../assets/png/warframe_profiles/equinox-prime.png"),
  },
  {
    name: "Excalibur",
    gender: "male",
    primeUmbra: "standard",
    auraPolarity: ["none"],
    progenitorElement: "electricity",
    hasLeverian: false,
    releaseYear: 2012,
    image: require("../assets/png/warframe_profiles/excalibur.png"),
  },
  {
    name: "Excalibur Prime",
    gender: "male",
    primeUmbra: "prime",
    auraPolarity: ["madurai"],
    progenitorElement: "electricity",
    hasLeverian: false,
    releaseYear: 2012,
    image: require("../assets/png/warframe_profiles/excalibur-prime.png"),
  },
  {
    name: "Excalibur Umbra",
    gender: "male",
    primeUmbra: "umbra",
    auraPolarity: ["madurai"],
    progenitorElement: "electricity",
    hasLeverian: false,
    releaseYear: 2018,
    image: require("../assets/png/warframe_profiles/excalibur-umbra.png"),
  },
  {
    name: "Frost",
    gender: "male",
    primeUmbra: "standard",
    auraPolarity: ["vazarin"],
    progenitorElement: "cold",
    hasLeverian: false,
    releaseYear: 2013,
    image: require("../assets/png/warframe_profiles/frost.png"),
  },
  {
    name: "Frost Prime",
    gender: "male",
    primeUmbra: "prime",
    auraPolarity: ["vazarin"],
    progenitorElement: "cold",
    hasLeverian: false,
    releaseYear: 2013,
    image: require("../assets/png/warframe_profiles/frost-prime.png"),
  },
  {
    name: "Gara",
    gender: "female",
    primeUmbra: "standard",
    auraPolarity: ["vazarin"],
    progenitorElement: "cold",
    hasLeverian: false,
    releaseYear: 2017,
    image: require("../assets/png/warframe_profiles/gara.png"),
  },
  {
    name: "Gara Prime",
    gender: "female",
    primeUmbra: "prime",
    auraPolarity: ["vazarin"],
    progenitorElement: "cold",
    hasLeverian: false,
    releaseYear: 2021,
    image: require("../assets/png/warframe_profiles/gara-prime.png"),
  },
  {
    name: "Garuda",
    gender: "female",
    primeUmbra: "standard",
    auraPolarity: ["madurai"],
    progenitorElement: "radiation",
    hasLeverian: false,
    releaseYear: 2018,
    image: require("../assets/png/warframe_profiles/garuda.png"),
  },
  {
    name: "Garuda Prime",
    gender: "female",
    primeUmbra: "prime",
    auraPolarity: ["madurai"],
    progenitorElement: "radiation",
    hasLeverian: false,
    releaseYear: 2022,
    image: require("../assets/png/warframe_profiles/garuda-prime.png"),
  },
  {
    name: "Gauss",
    gender: "male",
    primeUmbra: "standard",
    auraPolarity: ["naramon"],
    progenitorElement: "impact",
    hasLeverian: true,
    releaseYear: 2019,
    image: require("../assets/png/warframe_profiles/gauss.png"),
  },
  {
    name: "Gauss Prime",
    gender: "male",
    primeUmbra: "prime",
    auraPolarity: ["naramon"],
    progenitorElement: "impact",
    hasLeverian: true,
    releaseYear: 2024,
    image: require("../assets/png/warframe_profiles/gauss-prime.png"),
  },
  {
    name: "Grendel",
    gender: "male",
    primeUmbra: "standard",
    auraPolarity: ["naramon"],
    progenitorElement: "impact",
    hasLeverian: true,
    releaseYear: 2019,
    image: require("../assets/png/warframe_profiles/grendel.png"),
  },
  {
    name: "Grendel Prime",
    gender: "male",
    primeUmbra: "prime",
    auraPolarity: ["naramon"],
    progenitorElement: "impact",
    hasLeverian: true,
    releaseYear: 2023,
    image: require("../assets/png/warframe_profiles/grendel-prime.png"),
  },
  {
    name: "Gyre",
    gender: "female",
    primeUmbra: "standard",
    auraPolarity: ["naramon"],
    progenitorElement: "electricity",
    hasLeverian: false,
    releaseYear: 2022,
    image: require("../assets/png/warframe_profiles/gyre.png"),
  },
  {
    name: "Harrow",
    gender: "male",
    primeUmbra: "standard",
    auraPolarity: ["naramon"],
    progenitorElement: "magnetic",
    hasLeverian: false,
    releaseYear: 2017,
    image: require("../assets/png/warframe_profiles/harrow.png"),
  },
  {
    name: "Harrow Prime",
    gender: "male",
    primeUmbra: "prime",
    auraPolarity: ["naramon"],
    progenitorElement: "magnetic",
    hasLeverian: false,
    releaseYear: 2021,
    image: require("../assets/png/warframe_profiles/harrow-prime.png"),
  },
  {
    name: "Hildryn",
    gender: "female",
    primeUmbra: "standard",
    auraPolarity: ["naramon"],
    progenitorElement: "cold",
    hasLeverian: false,
    releaseYear: 2019,
    image: require("../assets/png/warframe_profiles/hildryn.png"),
  },
  {
    name: "Hildryn Prime",
    gender: "female",
    primeUmbra: "prime",
    auraPolarity: ["naramon"],
    progenitorElement: "cold",
    hasLeverian: false,
    releaseYear: 2023,
    image: require("../assets/png/warframe_profiles/hildryn-prime.png"),
  },
  {
    name: "Hydroid",
    gender: "male",
    primeUmbra: "standard",
    auraPolarity: ["naramon"],
    progenitorElement: "magnetic",
    hasLeverian: false,
    releaseYear: 2014,
    image: require("../assets/png/warframe_profiles/hydroid.png"),
  },
  {
    name: "Hydroid Prime",
    gender: "male",
    primeUmbra: "prime",
    auraPolarity: ["naramon"],
    progenitorElement: "magnetic",
    hasLeverian: false,
    releaseYear: 2017,
    image: require("../assets/png/warframe_profiles/hydroid-prime.png"),
  },
  {
    name: "Inaros",
    gender: "male",
    primeUmbra: "standard",
    auraPolarity: ["naramon"],
    progenitorElement: "heat",
    hasLeverian: false,
    releaseYear: 2016,
    image: require("../assets/png/warframe_profiles/inaros.png"),
  },
  {
    name: "Inaros Prime",
    gender: "male",
    primeUmbra: "prime",
    auraPolarity: ["naramon"],
    progenitorElement: "heat",
    hasLeverian: false,
    releaseYear: 2020,
    image: require("../assets/png/warframe_profiles/inaros-prime.png"),
  },
  {
    name: "Ivara",
    gender: "female",
    primeUmbra: "standard",
    auraPolarity: ["naramon"],
    progenitorElement: "toxin",
    hasLeverian: true,
    releaseYear: 2015,
    image: require("../assets/png/warframe_profiles/ivara.png"),
  },
  {
    name: "Ivara Prime",
    gender: "female",
    primeUmbra: "prime",
    auraPolarity: ["naramon"],
    progenitorElement: "toxin",
    hasLeverian: true,
    releaseYear: 2019,
    image: require("../assets/png/warframe_profiles/ivara-prime.png"),
  },
  {
    name: "Jade",
    gender: "female",
    primeUmbra: "standard",
    auraPolarity: ["universal", "madurai"],
    progenitorElement: "heat",
    hasLeverian: false,
    releaseYear: 2024,
    image: require("../assets/png/warframe_profiles/jade.png"),
  },
  {
    name: "Khora",
    gender: "female",
    primeUmbra: "standard",
    auraPolarity: ["vazarin"],
    progenitorElement: "toxin",
    hasLeverian: false,
    releaseYear: 2018,
    image: require("../assets/png/warframe_profiles/khora.png"),
  },
  {
    name: "Khora Prime",
    gender: "female",
    primeUmbra: "prime",
    auraPolarity: ["vazarin"],
    progenitorElement: "toxin",
    hasLeverian: false,
    releaseYear: 2022,
    image: require("../assets/png/warframe_profiles/khora-prime.png"),
  },
  {
    name: "Kullervo",
    gender: "male",
    primeUmbra: "standard",
    auraPolarity: ["naramon"],
    progenitorElement: "heat",
    hasLeverian: false,
    releaseYear: 2023,
    image: require("../assets/png/warframe_profiles/kullervo.png"),
  },
  {
    name: "Lavos",
    gender: "male",
    primeUmbra: "standard",
    auraPolarity: ["naramon"],
    progenitorElement: "magnetic",
    hasLeverian: true,
    releaseYear: 2020,
    image: require("../assets/png/warframe_profiles/lavos.png"),
  },
  {
    name: "Limbo",
    gender: "male",
    primeUmbra: "standard",
    auraPolarity: ["naramon"],
    progenitorElement: "electricity",
    hasLeverian: false,
    releaseYear: 2014,
    image: require("../assets/png/warframe_profiles/limbo.png"),
  },
  {
    name: "Limbo Prime",
    gender: "male",
    primeUmbra: "prime",
    auraPolarity: ["naramon"],
    progenitorElement: "electricity",
    hasLeverian: false,
    releaseYear: 2018,
    image: require("../assets/png/warframe_profiles/limbo-prime.png"),
  },
  {
    name: "Loki",
    gender: "male",
    primeUmbra: "standard",
    auraPolarity: ["naramon"],
    progenitorElement: "radiation",
    hasLeverian: false,
    releaseYear: 2012,
    image: require("../assets/png/warframe_profiles/loki.png"),
  },
  {
    name: "Loki Prime",
    gender: "male",
    primeUmbra: "prime",
    auraPolarity: ["naramon"],
    progenitorElement: "radiation",
    hasLeverian: false,
    releaseYear: 2014,
    image: require("../assets/png/warframe_profiles/loki-prime.png"),
  },
  {
    name: "Mag",
    gender: "female",
    primeUmbra: "standard",
    auraPolarity: ["madurai"],
    progenitorElement: "magnetic",
    hasLeverian: false,
    releaseYear: 2012,
    image: require("../assets/png/warframe_profiles/mag.png"),
  },
  {
    name: "Mag Prime",
    gender: "female",
    primeUmbra: "prime",
    auraPolarity: ["madurai"],
    progenitorElement: "magnetic",
    hasLeverian: false,
    releaseYear: 2013,
    image: require("../assets/png/warframe_profiles/mag-prime.png"),
  },
  {
    name: "Mesa",
    gender: "female",
    primeUmbra: "standard",
    auraPolarity: ["madurai"],
    progenitorElement: "magnetic",
    hasLeverian: false,
    releaseYear: 2014,
    image: require("../assets/png/warframe_profiles/mesa.png"),
  },
  {
    name: "Mesa Prime",
    gender: "female",
    primeUmbra: "prime",
    auraPolarity: ["madurai"],
    progenitorElement: "magnetic",
    hasLeverian: false,
    releaseYear: 2018,
    image: require("../assets/png/warframe_profiles/mesa-prime.png"),
  },
  {
    name: "Mirage",
    gender: "female",
    primeUmbra: "standard",
    auraPolarity: ["naramon"],
    progenitorElement: "radiation",
    hasLeverian: false,
    releaseYear: 2014,
    image: require("../assets/png/warframe_profiles/mirage.png"),
  },
  {
    name: "Mirage Prime",
    gender: "female",
    primeUmbra: "prime",
    auraPolarity: ["naramon"],
    progenitorElement: "radiation",
    hasLeverian: false,
    releaseYear: 2017,
    image: require("../assets/png/warframe_profiles/mirage-prime.png"),
  },
  {
    name: "Nekros",
    gender: "male",
    primeUmbra: "standard",
    auraPolarity: ["none"],
    progenitorElement: "toxin",
    hasLeverian: false,
    releaseYear: 2013,
    image: require("../assets/png/warframe_profiles/nekros.png"),
  },
  {
    name: "Nekros Prime",
    gender: "male",
    primeUmbra: "prime",
    auraPolarity: ["none"],
    progenitorElement: "toxin",
    hasLeverian: false,
    releaseYear: 2016,
    image: require("../assets/png/warframe_profiles/nekros-prime.png"),
  },
  {
    name: "Nezha",
    gender: "male",
    primeUmbra: "standard",
    auraPolarity: ["naramon"],
    progenitorElement: "heat",
    hasLeverian: true,
    releaseYear: 2015,
    image: require("../assets/png/warframe_profiles/nezha.png"),
  },
  {
    name: "Nezha Prime",
    gender: "male",
    primeUmbra: "prime",
    auraPolarity: ["naramon"],
    progenitorElement: "heat",
    hasLeverian: true,
    releaseYear: 2020,
    image: require("../assets/png/warframe_profiles/nezha-prime.png"),
  },
  {
    name: "Nidus",
    gender: "male",
    primeUmbra: "standard",
    auraPolarity: ["madurai"],
    progenitorElement: "toxin",
    hasLeverian: false,
    releaseYear: 2016,
    image: require("../assets/png/warframe_profiles/nidus.png"),
  },
  {
    name: "Nidus Prime",
    gender: "male",
    primeUmbra: "prime",
    auraPolarity: ["madurai"],
    progenitorElement: "toxin",
    hasLeverian: false,
    releaseYear: 2021,
    image: require("../assets/png/warframe_profiles/nidus-prime.png"),
  },
  {
    name: "Nova",
    gender: "female",
    primeUmbra: "standard",
    auraPolarity: ["naramon"],
    progenitorElement: "electricity",
    hasLeverian: true,
    releaseYear: 2013,
    image: require("../assets/png/warframe_profiles/nova.png"),
  },
  {
    name: "Nova Prime",
    gender: "female",
    primeUmbra: "prime",
    auraPolarity: ["naramon"],
    progenitorElement: "electricity",
    hasLeverian: true,
    releaseYear: 2014,
    image: require("../assets/png/warframe_profiles/nova-prime.png"),
  },
  {
    name: "Nyx",
    gender: "female",
    primeUmbra: "standard",
    auraPolarity: ["naramon"],
    progenitorElement: "radiation",
    hasLeverian: false,
    releaseYear: 2013,
    image: require("../assets/png/warframe_profiles/nyx.png"),
  },
  {
    name: "Nyx Prime",
    gender: "female",
    primeUmbra: "prime",
    auraPolarity: ["naramon"],
    progenitorElement: "radiation",
    hasLeverian: false,
    releaseYear: 2014,
    image: require("../assets/png/warframe_profiles/nyx-prime.png"),
  },
  {
    name: "Oberon",
    gender: "male",
    primeUmbra: "standard",
    auraPolarity: ["madurai"],
    progenitorElement: "toxin",
    hasLeverian: false,
    releaseYear: 2013,
    image: require("../assets/png/warframe_profiles/oberon.png"),
  },
  {
    name: "Oberon Prime",
    gender: "male",
    primeUmbra: "prime",
    auraPolarity: ["madurai"],
    progenitorElement: "toxin",
    hasLeverian: false,
    releaseYear: 2017,
    image: require("../assets/png/warframe_profiles/oberon-prime.png"),
  },
  {
    name: "Octavia",
    gender: "female",
    primeUmbra: "standard",
    auraPolarity: ["naramon"],
    progenitorElement: "radiation",
    hasLeverian: false,
    releaseYear: 2017,
    image: require("../assets/png/warframe_profiles/octavia.png"),
  },
  {
    name: "Octavia Prime",
    gender: "female",
    primeUmbra: "prime",
    auraPolarity: ["naramon"],
    progenitorElement: "radiation",
    hasLeverian: false,
    releaseYear: 2021,
    image: require("../assets/png/warframe_profiles/octavia-prime.png"),
  },
  {
    name: "Protea",
    gender: "female",
    primeUmbra: "standard",
    auraPolarity: ["universal"],
    progenitorElement: "heat",
    hasLeverian: false,
    releaseYear: 2020,
    image: require("../assets/png/warframe_profiles/protea.png"),
  },
  {
    name: "Protea Prime",
    gender: "female",
    primeUmbra: "prime",
    auraPolarity: ["universal"],
    progenitorElement: "heat",
    hasLeverian: false,
    releaseYear: 2024,
    image: require("../assets/png/warframe_profiles/protea-prime.png"),
  },
  {
    name: "Qorvex",
    gender: "male",
    primeUmbra: "standard",
    auraPolarity: ["vazarin"],
    progenitorElement: "radiation",
    hasLeverian: false,
    releaseYear: 2023,
    image: require("../assets/png/warframe_profiles/qorvex.png"),
  },
  {
    name: "Revenant",
    gender: "male",
    primeUmbra: "standard",
    auraPolarity: ["naramon"],
    progenitorElement: "cold",
    hasLeverian: false,
    releaseYear: 2018,
    image: require("../assets/png/warframe_profiles/revenant.png"),
  },
  {
    name: "Revenant Prime",
    gender: "male",
    primeUmbra: "prime",
    auraPolarity: ["naramon"],
    progenitorElement: "cold",
    hasLeverian: false,
    releaseYear: 2022,
    image: require("../assets/png/warframe_profiles/revenant-prime.png"),
  },
  {
    name: "Rhino",
    gender: "male",
    primeUmbra: "standard",
    auraPolarity: ["madurai"],
    progenitorElement: "impact",
    hasLeverian: false,
    releaseYear: 2012,
    image: require("../assets/png/warframe_profiles/rhino.png"),
  },
  {
    name: "Rhino Prime",
    gender: "male",
    primeUmbra: "prime",
    auraPolarity: ["madurai"],
    progenitorElement: "impact",
    hasLeverian: false,
    releaseYear: 2014,
    image: require("../assets/png/warframe_profiles/rhino-prime.png"),
  },
  {
    name: "Saryn",
    gender: "female",
    primeUmbra: "standard",
    auraPolarity: ["vazarin"],
    progenitorElement: "toxin",
    hasLeverian: false,
    releaseYear: 2013,
    image: require("../assets/png/warframe_profiles/saryn.png"),
  },
  {
    name: "Saryn Prime",
    gender: "female",
    primeUmbra: "prime",
    auraPolarity: ["vazarin"],
    progenitorElement: "toxin",
    hasLeverian: false,
    releaseYear: 2016,
    image: require("../assets/png/warframe_profiles/saryn-prime.png"),
  },
  {
    name: "Sevagoth",
    gender: "male",
    primeUmbra: "standard",
    auraPolarity: ["none"],
    progenitorElement: "impact",
    hasLeverian: false,
    releaseYear: 2021,
    image: require("../assets/png/warframe_profiles/sevagoth.png"),
  },
  {
    name: "Sevagoth Prime",
    gender: "male",
    primeUmbra: "prime",
    auraPolarity: ["none"],
    progenitorElement: "impact",
    hasLeverian: false,
    releaseYear: 2024,
    image: require("../assets/png/warframe_profiles/sevagoth-prime.png"),
  },
  {
    name: "Styanax",
    gender: "male",
    primeUmbra: "standard",
    auraPolarity: ["naramon"],
    progenitorElement: "cold",
    hasLeverian: true,
    releaseYear: 2022,
    image: require("../assets/png/warframe_profiles/styanax.png"),
  },
  {
    name: "Titania",
    gender: "female",
    primeUmbra: "standard",
    auraPolarity: ["madurai"],
    progenitorElement: "cold",
    hasLeverian: false,
    releaseYear: 2016,
    image: require("../assets/png/warframe_profiles/titania.png"),
  },
  {
    name: "Titania Prime",
    gender: "female",
    primeUmbra: "prime",
    auraPolarity: ["madurai"],
    progenitorElement: "cold",
    hasLeverian: false,
    releaseYear: 2020,
    image: require("../assets/png/warframe_profiles/titania-prime.png"),
  },
  {
    name: "Trinity",
    gender: "female",
    primeUmbra: "standard",
    auraPolarity: ["vazarin"],
    progenitorElement: "cold",
    hasLeverian: false,
    releaseYear: 2012,
    image: require("../assets/png/warframe_profiles/trinity.png"),
  },
  {
    name: "Trinity Prime",
    gender: "female",
    primeUmbra: "prime",
    auraPolarity: ["vazarin"],
    progenitorElement: "cold",
    hasLeverian: false,
    releaseYear: 2015,
    image: require("../assets/png/warframe_profiles/trinity-prime.png"),
  },
  {
    name: "Valkyr",
    gender: "female",
    primeUmbra: "standard",
    auraPolarity: ["madurai"],
    progenitorElement: "electricity",
    hasLeverian: false,
    releaseYear: 2013,
    image: require("../assets/png/warframe_profiles/valkyr.png"),
  },
  {
    name: "Valkyr Prime",
    gender: "female",
    primeUmbra: "prime",
    auraPolarity: ["madurai"],
    progenitorElement: "electricity",
    hasLeverian: false,
    releaseYear: 2016,
    image: require("../assets/png/warframe_profiles/valkyr-prime.png"),
  },
  {
    name: "Vauban",
    gender: "male",
    primeUmbra: "standard",
    auraPolarity: ["naramon"],
    progenitorElement: "heat",
    hasLeverian: false,
    releaseYear: 2013,
    image: require("../assets/png/warframe_profiles/vauban.png"),
  },
  {
    name: "Vauban Prime",
    gender: "male",
    primeUmbra: "prime",
    auraPolarity: ["naramon"],
    progenitorElement: "heat",
    hasLeverian: false,
    releaseYear: 2016,
    image: require("../assets/png/warframe_profiles/vauban-prime.png"),
  },
  {
    name: "Volt",
    gender: "male",
    primeUmbra: "standard",
    auraPolarity: ["madurai"],
    progenitorElement: "electricity",
    hasLeverian: false,
    releaseYear: 2012,
    image: require("../assets/png/warframe_profiles/volt.png"),
  },
  {
    name: "Volt Prime",
    gender: "male",
    primeUmbra: "prime",
    auraPolarity: ["madurai"],
    progenitorElement: "electricity",
    hasLeverian: false,
    releaseYear: 2015,
    image: require("../assets/png/warframe_profiles/volt-prime.png"),
  },
  {
    name: "Voruna",
    gender: "female",
    primeUmbra: "standard",
    auraPolarity: ["vazarin"],
    progenitorElement: "radiation",
    hasLeverian: true,
    releaseYear: 2022,
    image: require("../assets/png/warframe_profiles/voruna.png"),
  },
  {
    name: "Wisp",
    gender: "female",
    primeUmbra: "standard",
    auraPolarity: ["naramon"],
    progenitorElement: "heat",
    hasLeverian: false,
    releaseYear: 2019,
    image: require("../assets/png/warframe_profiles/wisp.png"),
  },
  {
    name: "Wisp Prime",
    gender: "female",
    primeUmbra: "prime",
    auraPolarity: ["naramon"],
    progenitorElement: "heat",
    hasLeverian: false,
    releaseYear: 2023,
    image: require("../assets/png/warframe_profiles/wisp-prime.png"),
  },
  {
    name: "Wukong",
    gender: "male",
    primeUmbra: "standard",
    auraPolarity: ["madurai"],
    progenitorElement: "impact",
    hasLeverian: false,
    releaseYear: 2015,
    image: require("../assets/png/warframe_profiles/wukong.png"),
  },
  {
    name: "Wukong Prime",
    gender: "male",
    primeUmbra: "prime",
    auraPolarity: ["madurai"],
    progenitorElement: "impact",
    hasLeverian: false,
    releaseYear: 2019,
    image: require("../assets/png/warframe_profiles/wukong-prime.png"),
  },
  {
    name: "Xaku",
    gender: "non binary",
    primeUmbra: "standard",
    auraPolarity: ["naramon"],
    progenitorElement: "magnetic",
    hasLeverian: false,
    releaseYear: 2020,
    image: require("../assets/png/warframe_profiles/xaku.png"),
  },
  {
    name: "Yareli",
    gender: "female",
    primeUmbra: "standard",
    auraPolarity: ["madurai"],
    progenitorElement: "magnetic",
    hasLeverian: false,
    releaseYear: 2021,
    image: require("../assets/png/warframe_profiles/yareli.png"),
  },
  {
    name: "Zephyr",
    gender: "female",
    primeUmbra: "standard",
    auraPolarity: ["vazarin"],
    progenitorElement: "impact",
    hasLeverian: false,
    releaseYear: 2014,
    image: require("../assets/png/warframe_profiles/zephyr.png"),
  },
  {
    name: "Zephyr Prime",
    gender: "female",
    primeUmbra: "prime",
    auraPolarity: ["vazarin"],
    progenitorElement: "impact",
    hasLeverian: false,
    releaseYear: 2018,
    image: require("../assets/png/warframe_profiles/zephyr-prime.png"),
  },
];
