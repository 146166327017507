import "./ColorIndicators.css";

export const ColorIndicators = () => {
  return (
    <div class="ci-container">
      <p class="ci-title">Color Indicators</p>
      <div class="ci-indicators">
        <div class="ci-indicator">
          <div class="ci-indicatorBox ci-correct"></div>
          <p class="ci-indicatorText">Right</p>
        </div>
        <div class="ci-indicator">
          <div class="ci-indicatorBox ci-partial"></div>
          <p class="ci-indicatorText">Partial</p>
        </div>
        <div class="ci-indicator">
          <div class="ci-indicatorBox ci-incorrect"></div>
          <p class="ci-indicatorText">Wrong</p>
        </div>
        <div class="ci-indicator">
          <div class="ci-indicatorBox ci-incorrect">
            <img
              src={require("../assets/png/icons/chevron-up-solid.png")}
              alt="ArrowIncorrect"
              width="30"
              height="20"
            />
          </div>
          <p class="ci-indicatorText">Higher</p>
        </div>
        <div class="ci-indicator">
          <div class="ci-indicatorBox ci-incorrect ci-lowerArrow">
            <img
              src={require("../assets/png/icons/chevron-up-solid.png")}
              alt="ArrowIncorrect"
              width="30"
              height="20"
            />
          </div>
          <p class="ci-indicatorText">Lower</p>
        </div>
      </div>
    </div>
  );
};
